
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { RouteNames } from "@/router/route-names";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const passwordField = ref('password');
    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(4).required().label("Password"),
      rememberMe: Yup.bool()
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      store.dispatch(Actions.PURGE_AUTH);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      // Send login request
      await store.dispatch(Actions.LOGIN, values);
      const errors = store.getters.getErrors;
      const haveError = errors.length > 0;

      if (!haveError) {
        Swal.fire({
          text: "¡Haz inicia sesión con exito!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Entrar al sistema",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(function () {
          // Go to page after successfully login
          router.push({ name: RouteNames.Welcome });
        });
      } else {
        /*Swal.fire({
          text: errors[0].description,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Intentalo otra vez...",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });*/
      }

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      passwordField
    };
  },
});
