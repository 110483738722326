export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "TITLES": {
          "SIGN_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sessión"])},
          "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "FORM": {
          "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo"])},
          "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
          "FORGOT_PASS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿ Has olvidado tu contraseña ?"])}
        },
        "BUTTOM": {
          "LOG_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar"])},
          "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresando..."])}
        }
      }
    }
  })
}
